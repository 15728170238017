<template>
  <div class="recruitmentdetaiks-view" v-if="detailsList">
    <!-- 公告详情页 -->

    <!-- 详情信息 -->
    <div class="affiche-content">
      <!-- 公告详情 -->
      <div class="content-details">
        <!-- 公告标题 -->
        <h3 class="affiche-title">
          {{ detailsList.bzTitle }}
        </h3>

        <!-- 发布详情 -->
        <div class="release-details">
          <div class="release-time" v-if="detailsList.createTime">
            <!--发布时间: {{ detailsList.createTime | disposeReleaseDate }}-->
          </div>
          <div class="article-source">文章来源: {{ detailsList.dwUnit }}</div>
          <div class="browse-sum">浏览次数: {{ detailsList.viewNum }}</div>
        </div>

        <!-- 跳转系统按钮框 -->
        <div class="jump-btn-box">
          <div
            class="jump-btn"
            @click="toApplyPage(detailsList.avtId)"
            v-if="detailsList.avtId"
          >
            点击进入报名系统
          </div>
        </div>

        <!-- 公告内容框 -->
        <div class="text-content">
          <div class="bcontent" v-html="detailsList.bzCentont"></div>
          <!-- <mavon-editor
            class="md"
            style="box-shadow: 0; background-color: #ffffff !important"
            :value="detailsList.bzCentont"
            :subfield="prop.subfield"
            :defaultOpen="prop.defaultOpen"
            :toolbarsFlag="prop.toolbarsFlag"
            :editable="prop.editable"
            :scrollStyle="prop.scrollStyle"
          ></mavon-editor> -->
          <!-- 附件框 -->
          <div class="accessory-box" v-if="detailsList.fileList">
            <div
              class="accessory-item"
              v-for="(item, index) in detailsList.fileList"
              :key="index"
            >
              <!-- <span @click="downloadFile(item)"
                >附件{{ index + 1 }}: {{ item.fjName }}</span
              > -->
              <a :href="url + item.fjAddress" download
                >附件{{ index + 1 }}: {{ item.fjName }}</a
              >
            </div>
          </div>
        </div>

        <!-- 岗位及咨询电话 -->

        <!-- 底部落款 -->
        <!-- <div class="bottom-inscribe"> -->
        <!-- 单位名称 -->
        <!-- <div class="unit-name">{{ detailsList.dwUnit }}</div> -->
        <!-- 创建日期 -->
        <!-- <div class="create-date" v-if="detailsList.createTime">
            {{ detailsList.createTime | disposeDate }}
          </div> -->
        <!-- </div> -->
      </div>

      <!-- 右边列表框 -->
      <!-- 公告目录 -->
      <div class="right-list-box">
        <!-- <div class="affiche-directory">
          <div class="title">公告目录</div>
          <div class="affiche-list">
            <span v-for="(item, index) in directoryList" :key="index">
              <a
                class="affiche-item"
                :class="{ shewActvie: isIndex === index }"
                @mouseover="mouseover(index)"
                @mouseout="mouseout"
                @click="jump(index)"
                >{{ item }}</a
              >
            </span>
          </div>
        </div> -->

        <!-- 最新公告 -->
        <div class="new-affiche">
          <div class="title">最新公告</div>
          <ul>
            <li
              class="new-affiche-item"
              v-for="(item, i) in newAfficheList"
              :key="i"
              @click="getAfficheData(item.ywId, item.rlink)"
            >
              <div class="top">
                <div class="type">{{ item.avtName }}</div>
                <span class="date">{{ item.createTime }}</span>
              </div>
              <div class="text">{{ item.bzTitle }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { marked } from "marked";
import common from "../../api/common";
export default {
  name: "RecruitDetaiks",
  data() {
    return {
      url: "https://ksbmpt.hj-blks.com/prod-api",
      // 招聘详情id
      id: "",

      directoryList: [],

      // 最新公告数据列表
      newAfficheList: [],

      isIndex: NaN,

      // 详情列表数据
      detailsList: [],
    };
  },
  // activated() {
  //   this.getAfficheDetails();
  //   this.jump();
  // },
  filters: {
    // 处理公告底部日期的方法 将日期处理成 "2022年6月23日" 的格式
    disposeDate(date) {
      let time =
        date.substr(0, 10).replace("-", "年").replace("-", "月") + "日";
      return time;
    },

    // 处理发布日期
    disposeReleaseDate(date) {
      return date.substr(0, 10);
    },
  },
  computed: {
    prop() {
      let data = {
        subfield: false, // 单双栏模式
        defaultOpen: "preview", //edit： 默认展示编辑区域 ， preview： 默认展示预览区域
        editable: false,
        toolbarsFlag: false,
        scrollStyle: true,
      };
      return data;
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.getAfficheDetails();
    this.init();
    // 初始化目录，获取目录标题
    this.jump();
  },

  methods: {
    // 获取公告详情
    getAfficheData(id, url) {
      if (url) {
        window.open(url, "_blank");
      } else {
        this.$router.push({ name: "RecruitDetaiks", params: { id: id } });
      }
    },
    init() {
      this.newAfficheList = [];
      var data = {};
      common.getHotRecruitment(data).then((res) => {
        // console.log(res.rows);
        for (var i = 0; i < res.rows.length; i++) {
          if (res.rows[i].createTime) {
            res.rows[i].createTime = res.rows[i].createTime.substr(0, 10);
          }
        }
        this.newAfficheList = res.rows;
        // var k = 0;
        // for (var i = 0; i < res.rows.length; i++) {
        //   if (k < 7) {
        //     var pp = res.rows;
        //     var time = (pp[i].createTime + "").substr(0, 10);
        //     var p = { column: pp[i].bzTitle, date: time, text: pp[i].ywDesc };
        //     this.newAfficheList.push(p);
        //   }
        //   k++;
        // }
      });
    },
    // 跳转报名页面方法
    toApplyPage(id) {
      //  this.$router.push({ name: "applyPage" });
      var href = this.$api + "#/login?avtId=" + id;
      window.open(href, "_blank");
    },

    // 鼠标移上公告目录显示高亮
    mouseover(i) {
      this.isIndex = i;
    },

    // 鼠标移出去除公告目录显示高亮
    mouseout() {
      this.isIndex = NaN;
    },

    // 点击目录跳转对应位置
    jump(index) {
      var items = document.querySelectorAll("h6");
      console.log("items====>", items);
      for (var i = 0; i < items.length; i++) {
        this.directoryList.push(new Set(items[i].innerText));
        if (i === index) {
          items[i].scrollIntoView({
            block: "start", // 默认跳转到顶部
            behavior: "smooth", // 滚动匀速
          });
        }
      }

      console.log(this.directoryList);
    },

    // 获取详情数据
    getAfficheDetails() {
      common.operationGuide(this.$route.params.id).then((res) => {
        // console.log(res.data.ywCentont);
        // res.data.ywCentont = marked(res.data.ywCentont, {
        //   sanitize: true,
        // });
        this.detailsList = res.data;
        // console.log(this.detailsList);
        //text-content  父节点
        let DomList1 = document
          .getElementsByClassName("text-content")[0]
          .querySelectorAll("div");

        for (let i in DomList1) {
          if (DomList1[i].style) {
            DomList1[i].style.backgroundColor = "#ffffff";
          }
        }

        let DomList = document
          .getElementsByClassName("text-content")[0]
          .querySelectorAll("img");
        for (let i in DomList) {
          if (DomList[i].style) {
            DomList[i].style.width = "70%";
            DomList[i].style.display = "flex";
            DomList[i].style.margin = "0 auto";
          }
        }
      });
    },

    // 点击下载附件的方法
    downloadFile(data) {
      console.log(data);
      common.getDownloadFile(data.fjAddress).then((res) => {
        console.log(res, "sadclkjhkh");
        // if (!res) {
        //   return;
        // }
        // let url = window.URL.createObjectURL(
        //   new Blob([res], { type: "application/octet-stream" })
        // );
        // let link = document.createElement("a");
        // link.style.display = "none";
        // link.href = url;
        // const fileName = data.fjName; //文件名称
        // link.setAttribute("download", fileName);
        // document.body.appendChild(link);
        // link.click();
        // window.URL.revokeObjectURL(url); //释放url对象所占资源
        // document.body.removeChild(link); //用完删除
        ///////////////////////////////
        // const blob = new Blob([res], {
        //   type: "application/json;chartset=UTF-8",
        // });
        // const link = document.createElement("a");
        // const body = document.querySelector("body");
        // const filename = data.fjName; //文件名称
        // link.href = window.URL.createObjectURL(blob);
        // link.download = filename;
        // // fix Firefox
        // link.style.display = "none";
        // body.appendChild(link);
        // link.click();
        // body.removeChild(link);
        // window.URL.revokeObjectURL(link.href);
        //////////////////////
        // let pdfUrl = window.URL.createObjectURL(
        //   new Blob([res], { type: `application/msword` })
        // );
        // const fname = ""; // 下载文件的名字
        // const link = document.createElement("a");
        // link.href = pdfUrl;
        // link.setAttribute("download", fname);
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
      });
    },
  },

  watch: {
    $route() {
      if (this.$route.params.id) {
        this.getAfficheDetails();
      }
    },
  },
};
</script>

<style scoped>
img {
  width: 50%;
  height: 50%;
}

.recruitmentdetaiks-view {
  width: 100%;
  background: #f5f5f5;
  margin-bottom: 50px;
}

.navigation-bar {
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  opacity: 1;
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 50px;
}

.navigation-bar > div {
  margin-right: 12px;
}
.right-icon {
  width: 16px;
  height: 16px;
  background: url(../../assets/images/right-arrow.png) no-repeat 100%/100%;
}

.current-page-title {
  font-size: 16px;
  font-weight: 500;
  color: #e01811;
  opacity: 1;
}

.affiche-content {
  width: 100%;
  display: flex;
}

.content-details {
  background: #ffffff;
  margin-right: 32px;
  padding-top: 48px;
  width: 68%;
  box-sizing: border-box;
}

.affiche-title {
  text-align: center;
  font-size: 1.98vw;
  font-family: PingFang SC;
  font-weight: 600;
  color: #e01811;
  opacity: 1;
  margin: 0;
}

.release-details {
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  opacity: 1;
  margin-top: 16px;
}

.release-details > div {
  color: #333333;
  margin-right: 10px;
}

.jump-btn-box {
  display: flex;
  justify-content: center;
  margin: 40px 65px 40px 65px;
  border-top: 1px solid #eeeeee;
}

.jump-btn {
  width: 224px;
  height: 40px;
  color: #ffffff;
  background: #e01811;
  opacity: 1;
  border-radius: 0px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  margin-top: 24px;
}

.text-content {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 45px;
  color: #666666;
  opacity: 1;
  margin: 0px 48px;
  text-indent: 2em;
}

.jobsConsult-box {
  margin: 28px 90px 0px 48px;
}

.jobsConsult-title,
.accessory-title {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 45px;
  color: #333333;
  opacity: 1;
  margin: 0px;
}

.jobsConsult-list {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 60px;
  color: #666666;
  opacity: 1;
}

.recruitment-title {
  text-indent: 10px;
}

.accessory-item {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 60px;
  color: #006ff0;
  text-decoration: underline;
  opacity: 1;
  cursor: pointer;
}

.bottom-inscribe {
  height: 73px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 50px;
  color: #333333;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0px 48px 40px 0px;
}

.unit-name,
.create-date {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 50px;
  color: #333333;
  opacity: 1;
}
.right-list-box {
  width: 30%;
  height: 100%;
  box-sizing: border-box;
}

.affiche-directory {
  width: 100%;
  background: #ffffff;
  padding: 32px;
  padding-right: 24px;
  box-sizing: border-box;
}

.affiche-list {
  display: flex;
  flex-direction: column;
}

.affiche-list > a {
  display: inline-block;
}

.new-affiche {
  width: 100%;
  background: #ffffff;
  padding: 32px;
  padding-right: 24px;
  /* margin-top: 48px; */
  box-sizing: border-box;
  cursor: pointer;
}

.right-list-box .title {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 50px;
  color: #e01811;
  opacity: 1;
  margin-bottom: 22px;
}

.affiche-item {
  width: 100%;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  opacity: 1;
  line-height: 50px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
}

.new-affiche-item {
  margin-bottom: 24px;
}

.new-affiche-item .top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2px;
}

.shewActvie {
  text-decoration: underline;
}
.top > .type {
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #e01811;
  opacity: 1;
}

.top > .date {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #999999;
  opacity: 1;
}

.new-affiche-item .text {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 30px;
  color: #333333;
  opacity: 1;
  /* opacity: 1; */
  /* 超出自动换行 */
  word-wrap: break-word;
  word-break: break-all;
}
ul {
  height: 500px;
  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
mavon-editor {
  background-color: #ffffff !important;
}
</style>